import { Button } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ViewListIcon from '@mui/icons-material/ViewList';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import { visuallyHidden } from '@mui/utils';
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import moment from "moment";
import 'moment/min/locales.min';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import axios from "../AxiosBase.js";


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export default function SurveyList(props) {

    const [rows, setRows] = useState(props.surveys);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('date');
    const [page, setPage] = useState(0);
    const [activeTab, setActiveTab] = useState('all');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedRequest, setSelectedRequest] = useState({ ref: null, description: "", regions: [], project: {}, date: null, status: [0, 0], requested_by: { first_name: "", last_name: "" } })
    const [miniMap, setMiniMap] = useState();
    const [editRequestID, setEditRequestID] = useState(-1);
    const [selectedStatusValue, setSelectedStatusValue] = useState("N")
    const [onlyShowRequested, setOnlyShowRequested] = useState(false)
    const [onlyShowManageable, setOnlyShowManageable] = useState(false)
    const [selectedMapBounds, setSelectedMapBounds] = useState()
    const [showRequestInfo, setShowRequestInfo] = useState(false)
    const [statusUpdateMessage, setStatusUpdateMessage] = useState("")
    const [showSuccess, setShowSuccess] = useState(false)
    const statusTypes = props.statusTypes

    const refresh = props.refresh

    const mapContainer = useRef();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const showRequestDetails = (requestDetails) => {

        let bounds = []

        requestDetails.bounds.map((mbounds) =>
            bounds.push(...mbounds)
        )

        const map = new mapboxgl.Map({
            container: mapContainer.current,
            style: "mapbox://styles/tmas45/ck04e7qr60biy1cukg3s86l2n",
            center: [-3.28330050, 53.64207571],
            zoom: 5,
            attributionControl: false,
            interactive: false
        });

        map.on("load", () => {
            let nav = new mapboxgl.NavigationControl({
                showZoom: true
            })
            map.addControl(nav)
            // map.fitBounds(bounds)
            map.resize();
            map.addSource("requested-area", {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': [
                            requestDetails.bounds[0]
                        ]
                    }
                }
            });

            // Add a new layer to visualize the polygon.
            map.addLayer({
                'id': "requested-area-polygon",
                'type': 'fill',
                'source': "requested-area", // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': '#f97b1a', // blue color fill
                    'fill-opacity': 0.5
                }
            });

            map.addLayer({
                'id': "requested-area-polygon-outline",
                'type': 'line',
                'source': "requested-area",
                'layout': {},
                'paint': {
                    'line-color': '#000',
                    'line-width': 3
                }
            });

            const bounds = new mapboxgl.LngLatBounds(
                requestDetails.bounds[0][0],
                requestDetails.bounds[0][0]
            );

            // Extend the 'LngLatBounds' to include every coordinate in the bounds result.

            for (const coord of requestDetails.bounds[0]) {
                bounds.extend(coord);
            }

            map.fitBounds(bounds, {
                padding: 20
            });

            setSelectedMapBounds([bounds._ne.lng, bounds._ne.lat, bounds._sw.lng, bounds._sw.lat])

        })

        setSelectedRequest(requestDetails)
        setMiniMap(map)
        setShowRequestInfo(true)

    }

    const headCells = [
        {
            id: 'Ref',
            left: null,
            center: true,
            disablePadding: true,
            label: 'ID',
        },
        {
            id: 'description',
            left: true,
            disablePadding: false,
            label: 'Description',
        },
        {
            id: 'project',
            left: false,
            disablePadding: false,
            label: "Project",
        },
        {
            id: 'regions',
            left: false,
            disablePadding: false,
            label: 'Region(s)',
        },
        {
            id: 'date',
            left: false,
            disablePadding: false,
            label: 'Date Requested',
        },
        {
            id: 'status',
            left: false,
            disablePadding: false,
            label: 'Status',
        },
    ];

    function EnhancedTableHead(props) {

        const { order, orderBy, onRequestSort } =
            props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.left ? 'left' : headCell.center ? 'center' : 'right'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={{ fontWeight: 700, fontSize: "15px", backgroundColor: "#fafafa" }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }

    EnhancedTableHead.propTypes = {
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleSaveStatusChange = () => {
        let formData = {
            "survey_id": editRequestID,
            "status": selectedStatusValue,
            "status_message": statusUpdateMessage
        }

        axios.post(process.env.REACT_APP_BACKEND_URL + "/survey_request/update/",
            formData,
            { withCredentials: true }
        )
            .then((response) => {
                if (response.status === 202) {
                    refresh();
                    setShowSuccess(true)
                    setStatusUpdateMessage("")
                    setShowRequestInfo(false)
                }
            });
    }

    useEffect((rows) => {
        if (rows !== props.surveys) {
            setRows(props.surveys);
        }
    }, [props.surveys])

    useEffect(() => {

        let rowsToShow = [];
         
        // onlyShowRequested && !onlyShowManageable (t,f)
        if(onlyShowRequested && !onlyShowManageable){
            rowsToShow = props.surveys.filter(survey => activeTab === "user-requests" ? survey.status[0] === "R" && survey.requested_by.id === props.user_id : survey.status[0] === "R")
        }

        // !onlyShowRequested && onlyShowManageable (f,t) -- everythign manageable that you have created
        if(!onlyShowRequested && onlyShowManageable){
            rowsToShow = props.surveys.filter(survey => activeTab === "user-requests" ? survey.requested_by.id === props.user_id && survey.is_survey_admin : survey.is_survey_admin)
        }

        // onlyShowRequest && onlyShowManageable (t,t) -- Show all currently requested surveys that you manage
        if(onlyShowRequested && onlyShowManageable){
            rowsToShow = props.surveys.filter(survey => activeTab === "user-requests" ? survey.requested_by.id === props.user_id && survey.is_survey_admin && survey.status[0] === "R" : survey.is_survey_admin && survey.status[0] === "R")
        }

        // !onlyShowRequested && !onlyShowMangeable (f,f)
        if(!onlyShowRequested && !onlyShowManageable){
            rowsToShow = props.surveys.filter(survey => activeTab === "user-requests" ? survey.requested_by.id === props.user_id : props.surveys)
        } 
        
        setRows(rowsToShow)

    }, [activeTab, onlyShowRequested, onlyShowManageable])

    return (
        <>
            <Box sx={{ width: '95%', marginLeft: "2.5%", marginTop: "20px", boxShadow: "none" }}>
                <Paper sx={{ width: '95%', mb: 2, marginLeft: "2.5%" }}>
                    <>
                        <h5 style={{ margin: "40px 0 30px 0", fontWeight: 500, float: "left" }}><ViewListIcon />&nbsp; Requested Surveys</h5>
                        <div style={{ margin: "30px 0 30px 100px", float: "left" }}>
                            <Button variant="contained" style={{ marginRight: 10, backgroundColor: "transparent", borderRadius: 0, boxShadow: "none", textTransform: "capitalize", borderBottom: activeTab === "all" ? "3px solid #222" : "none", fontWeight: activeTab === "all" ? 700 : 500 }} onClick={() => setActiveTab("all")}>All Requests</Button>
                            <Button variant="contained" type="default" style={{ backgroundColor: "transparent", borderRadius: 0, boxShadow: "none", textTransform: "capitalize", borderBottom: activeTab === "user-requests" ? "3px solid #222" : "none", fontWeight: activeTab === "user-requests" ? 700 : 500 }} onClick={() => setActiveTab("user-requests")}>Your Requested Surveys</Button>
                        </div>

                
                        <div style={{ marginTop: "40px", float: "right" }}>
    

                            <FormGroup style={{display:"inline"}}>
  <FormControlLabel control={<Checkbox value={onlyShowRequested}  style={{color:"#222"}} onChange={() => setOnlyShowRequested(!onlyShowRequested)} /> } label="Only Show Requested" />
  {rows.filter(survey => survey.is_survey_admin).length > 0 && <FormControlLabel control={<Checkbox checked={onlyShowManageable}  style={{color:"#222"}} onChange={() => setOnlyShowManageable(!onlyShowManageable)}/>} label="Only Show Managable" />}
</FormGroup>
                        </div>

                        <TableContainer style={{ borderTop: "1px solid #ddd" }}>
                            <Table
                                stickyHeader
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={'medium'}
                            >
                                <EnhancedTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onSelectAllClick={""}
                                    onRequestSort={handleRequestSort}
                                    rowCount={rows.length}
                                />
                                <TableBody>

                                    {stableSort(props.project !== 0 ? rows.filter(survey => survey.project.id === props.project) : rows, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => {

                                
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={index}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                        width="5%"
                                                        align="center"
                                                        onClick={() => { setEditRequestID(row.ref); setSelectedStatusValue(row.status[0]); showRequestDetails(row) }}
                                                        style={{ textDecoration: "underline", cursor: "pointer" }}>R-{row.ref}
                                                    </TableCell>

                                                    <TableCell align="left" width="25%" style={{ paddingLeft: "30px" }}>{row.description}</TableCell>
                                                    <TableCell align="right" width="15%">{row.project['name']}</TableCell>
                                                    <TableCell align="right" width="20%" style={{ paddingLeft: "" }}>{row.regions.map((region) => <><span>{region.name}</span><span style={{ color: region.funded ? "green" : "#e74c3c" }}>{region.funded ? " (Funded Region)" : " (Non-Funded Region)"}</span><br /></>)}</TableCell>
                                                    <TableCell align="right" width="15%">{moment(row.date).locale('en-gb').format("LL - HH:mm:ss")}</TableCell>
                                                    <TableCell align="right" width="10%" style={{ color: row.status[1] === "Requested" ? "darkorange" : row.status[1] === "Completed" || row.status[1] === "Accepted" ? "#2e7d32" : "#e74c3c" }}>
                                                        {row.status[1]}
                                                    </TableCell>
                                                </TableRow>
                                                // 2e7d32
                                            );

                                            
                                        })}
                                    {emptyRows > 0 && (
                                        <TableRow
                                            style={{
                                                height: (33) * emptyRows,
                                            }}
                                        >
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                </Paper>
            </Box>


            <div className={"request-info-modal"} style={{
                height: "auto",
                width: "auto",
                maxHeight: "auto",
                padding: "20px",
                paddingTop: 0,
                margin: "20px",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: showRequestInfo ? "translate(-50%,-50%)" : "translate(-2000px,-50%)",
                zIndex: 99,
                opacity: showRequestInfo ? 1 : 0,
                transition: "transform 0.2s ease-out, opacity 0.2s ease-out",
                display: "flex",
                flexDirection: "column",
                background: "#FFFFFF",
                borderRadius: 8,
                boxShadow: "0 0 8px 2px #efefef"
            }}>
                <div style="maxbox-header" style={{ height: "50px" }}>
                    <h5 style={{ float: "left", lineHeight: "50px" }}>Survey Request Details (R-{selectedRequest.ref})</h5>
                    {/* <FontAwesomeIcon icon="times" style={{ float: "right", height: 50, marginRight: 10, cursor: "pointer" }} onClick={() => { map.remove() }} /> */}
                    <span style={{ cursor: "pointer", lineHeight: "50px", float: "right" }} onClick={() => { setShowRequestInfo(false); miniMap.remove() }}><CloseIcon /></span>
                </div>
                <Paper className={"theme-bg-primary"} style={{
                    height: "600px",
                    width: "1000px",
                }}>
                    <div className={"survey-request-more-details"} style={{ width: "50%", maxWidth: "50%", height: "100%", float: "left", overflowY: "auto", overflowX: "hidden", wordWrap: "break-word" }}>
                        <><h5>Request Details</h5>
                            <table style={{ marginBottom: 20, marginTop: 15 }}>
                                <tbody>
                                <tr>
                                    <td>Request ID</td>
                                    <td>R-{selectedRequest.ref}</td>
                                </tr>
                                <tr>
                                    <td>Description</td>
                                    <td>{selectedRequest.description}</td>
                                </tr>
                                <tr>
                                    <td>Region(s)</td>
                                    <td>{selectedRequest.regions.map((region, key) => <><span key={key}>{region.name}</span><span style={{ color: region.funded ? "green" : "#e74c3c" }}>{region.funded ? " (Funded Region)" : " (Non-Funded Region)"}</span><br /></>)}</td>
                                </tr>
                                <tr>
                                    <td>Project</td>
                                    <td>{selectedRequest.project['name']}</td>
                                </tr>
                                <tr>
                                    <td>Date</td>
                                    <td>{moment(selectedRequest.date).locale('en-gb').format("LL - HH:mm:ss")}</td>
                                </tr>
                                <tr>
                                    <td>Status</td>

                                    {selectedRequest.is_survey_admin ?
                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                            <InputLabel id="demo-simple-select-standard-label">Change Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={selectedStatusValue !== "N" ? selectedStatusValue : selectedRequest.status[0]}
                                                onChange={(e) => setSelectedStatusValue(e.target.value)}
                                                label="Change Status"
                                                style={{ display: "inline-block !important", zIndex: 999999 }}
                                            >
                                                {statusTypes.map((status, key) =>
                                                    <MenuItem value={status[0]} key={key} className={"survey-change-dropdown"} > {status[1]}</MenuItem>

                                                )}
                                            </Select>
                                        </FormControl>
                                        :
                                        <td style={{ color: selectedRequest.status[1] === "Requested" ? "darkorange" : selectedRequest.status[1] === "Completed" || selectedRequest.status[1] === "Accepted" ? "#2e7d32" : "#e74c3c" }}>{selectedRequest.status[1]}</td>
                                    }
                                </tr>

                                {selectedStatusValue !== selectedRequest.status[0] &&
                                    <>
                                        <tr>
                                            <td></td>
                                            <td><TextField
                                                id="status-change-message"
                                                type="text"
                                                label={"Send the client a message"}
                                                multiline
                                                rows={3}
                                                value={statusUpdateMessage}
                                                style={{ width: "90%" }}
                                                onChange={(e) => setStatusUpdateMessage(e.target.value)} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <Button type="default" variant="contained" style={{ background: "#FFF" }} onClick={() => { setStatusUpdateMessage(""); setSelectedStatusValue(selectedRequest.status[0]) }}>Cancel</Button>
                                                <Button type="default" variant="contained" style={{ background: "#222", color: "#FFF" }} onClick={() => handleSaveStatusChange()}>Submit and Save</Button>
                                            </td>
                                        </tr>

                                    </>
                                }
                                </tbody>
                            </table>

                            {selectedRequest.is_survey_admin ? <>
                                <h5>Client Details</h5>
                                <table style={{ marginTop: 15 }}>
                                    <tr>
                                        <td>Client's Name</td>
                                        <td>{selectedRequest.requested_by.first_name} {selectedRequest.requested_by.last_name}</td>
                                    </tr>
                                    <tr>
                                        <td>Client's Email</td>
                                        <td><a href={`mailto: ${selectedRequest.requested_by.email}`}>{selectedRequest.requested_by.email}</a></td>
                                    </tr>
                                </table>
                            </>
                                : <></>
                            }

                            <p style={{ textAlign: 'center', marginTop: 20 }}> {props.visivi_url ?
                                <Button variant="contained" type="default" style={{ marginTop: 20, background: "#FFF", color: "#222", minWidth: "150px", fontWeight: 600, borderBottom: "3px solid #222", boxShadow: "none", borderRadius: 0 }} onClick={() => {
                                    let url_str = encodeURIComponent(JSON.stringify(selectedMapBounds))
                                    window.open(`${props.visivi_url}?bnds=${url_str}&referrer=BookAirOps`, '_blank')
                                }}>View Bounds in Visivi</Button>
                                : <></>}</p>
                        </>

                    </div>
                    <div ref={mapContainer} style={{ width: "50%", height: "100%", float: "right" }}>
                    </div>

                </Paper>
            </div >

            <Snackbar open={showSuccess}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ marginTop: 50 }}
                autoHideDuration={6000}
                onClose={() => setShowSuccess(false)}>

                <MuiAlert elevation={6} variant="filled" onClose={() => setShowSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Survey Status has been updated
                </MuiAlert>
            </Snackbar>
        </>
    );
}