import React from 'react';

class Login extends React.Component {

    render() {
        return (
            <div className="d-flex" style={{ "height": "100vh", "width": "100vw" }}>
                <div className="col-md-6 offset-md-3" style={{ "marginTop": "auto", "marginBottom": "auto" }}>
                    <div className="row">
                        <div className="col">
                            <h3 className="text-center">Sign In</h3>
                        </div>
                    </div>
                    <div className="row" style={{ "marginTop": "5px", "paddingBottom": "50px" }}>
                        <div className="col text-center">
                            <div className="row" style={{ "paddingBottom": "16px", "paddingTop": "16px" }}>
                                <div className="col">
                                    <p>Visivi Auth is a new centralised system for logging in to all Visivi
                                        Services. This will mean
                                        you don't have to worry about having many different passwords, just use the
                                        single login here!
                                    </p>
                                </div>
                            </div>
                            <a className="btn btn-outline-primary btn-lg" href={process.env.REACT_APP_BACKEND_URL + '/accounts/visauth/login/?next=/users/airops_authenticated'}>Sign in with Visivi Auth</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Login;