
import HelpIcon from '@mui/icons-material/Help';
import LogoutIcon from '@mui/icons-material/Logout';
import MapIcon from '@mui/icons-material/Map';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PasswordIcon from '@mui/icons-material/Password';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewListIcon from '@mui/icons-material/ViewList';
import FormControl from '@mui/material/FormControl';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";


export const Header = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [serverVersion, setServerVersion] = React.useState("0.0.0.0")

    const fetchServerVersion = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + "/version", {
            withCredentials: true,
        })
            .then((response) => {
                setServerVersion(response.data)
            });
    }
    const handleChange = (e) => {
        let selectedProject;

        if(e.target.value === 0){
            selectedProject = {id: 0, name:"Show From All Projects"}
            props.setProject({...props.projects, current:selectedProject})
        }
        else{
            let title, description, book_survey_help_url, visivi_url, proj
            proj = props.projects.allProjects.features.filter(project => project.id === e.target.value)[0];
                    selectedProject = { id: proj.id, name: proj.properties.name }
                    title = proj.properties.book_survey_title;
                    description = proj.properties.book_survey_request_description
                    book_survey_help_url = proj.properties.book_survey_help_url
                    visivi_url = proj.properties.visivi_url;
            props.setProject({ ...props.projects, current: selectedProject, title: title, textAreaDesc: description, help_url: book_survey_help_url, visivi_url: visivi_url, needsUpdate: true })
        }

        localStorage.setItem('current-project', JSON.stringify(selectedProject))
       
    }

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        fetchServerVersion()
        // CALL API QUERY TO FETCH THE APPLICATION VERSION AND SERVER VERSION
    }, [])

    return (
        <div style={{ height: "56px", maxHeight: "56px", width: "100vw", margin: 0, padding: 0, position: "fixed", top: 0, backgroundColor: "#FFFFFF", zIndex: 9999 }}>
            <h4 style={{ fontWeight: 500, lineHeight: "56px", margin: 0, paddingLeft: 20, width: "auto", float: "left", marginRight: "20px" }}>{props.title}</h4>
            <div style={{ marginLeft: "100px", height: "40px", marginTop: "0px", boxSizing: "border-box" }}>

                {props.projects.allProjects.count > 1 && !props.admin ?
                    <FormControl variant="standard" sx={{ minWidth: 50, maxHeight: "40px", marginLeft: "10px", marginTop: "14px" }}>
                        <Select
                            id="select-project"
                            value={props.projects.current.id}
                            onChange={handleChange}
                            autoWidth
                            style={{ border: "none !important", outline: "none" }}
                            inputProps={{ 'aria-label': 'Without label' }}
                            disableUnderline
                        >
                            <MenuItem value={0} key={-1}>Show From All</MenuItem>
                            {props.projects.allProjects.features ? props.projects.allProjects.features.map((project, key) => {
                                    return <MenuItem value={project.id} key={key}>{project.properties.name}</MenuItem>   
                            }
                            ) : <></>}
                        </Select>
                    </FormControl>
                    :
                    <h5 style={{ lineHeight: "56px", paddingLeft: "0px", fontSize: 18 }}>{props.projects.current.name}</h5>
                }

            </div>
            <div style={{ position: "absolute", left: "50%", top: 0, height: "56px", lineHeight: "56px", width: "auto", transform: "translate(-50%,0)" }}>
                <ul className="menu" style={{ listStyleType: "none", textAlign: "center" }}>
                    <li className={props.activePage === "survey-request" ? "active-page" : ""} onClick={() => props.setActivePage("survey-request")}><MapIcon />  &nbsp; Request a Survey</li>
                    <li className={props.activePage === "survey-list" ? "active-page" : ""} onClick={() => props.setActivePage("survey-list")}><ViewListIcon /> &nbsp; List of Surveys</li>
                    {props.admin && <li className={props.activePage === "admin-settings" ? "active-page" : ""} onClick={() => props.setActivePage("admin-settings")}><SettingsIcon /> &nbsp; Survey Manager Setup</li>}
                </ul>
            </div>
            <div style={{ position: "absolute", top: 0, right: "20px", lineHeight: "56px", cursor: "pointer" }}>
                <Tooltip title="Help"><HelpIcon style={{ marginRight: 30, color: "#aaa" }} className={"header-right-icon"} onClick={() => window.open(props.projects.help_url, '_blank')} /></Tooltip>
                <Tooltip title="Settings"><SettingsIcon style={{ marginRight: 10, color: "#aaa" }} className={"header-right-icon"} onClick={handleMenu} /></Tooltip>
            </div>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                style={{ marginTop: 50 }}
            >
                <MenuItem onClick={() => {
                    window.open(`${process.env.REACT_APP_AUTH_URL}/accounts/password/change/`)
                }}><PasswordIcon /> &nbsp; Change Password</MenuItem>
                <MenuItem onClick={() => {
                    window.open(process.env.REACT_APP_AUTH_URL)
                }}><OpenInNewIcon /> &nbsp; Open Visivi Auth</MenuItem>
                <MenuItem onClick={() => {

                    // log the user out on front end
                    sessionStorage.setItem("loginState", false);
                    Cookies.remove('api_token')
                    Cookies.remove('csrftoken')
                    localStorage.removeItem("current-project")

                    // Redirect to log the user out on backend
                    window.open(`${process.env.REACT_APP_AUTH_URL}/accounts/logout/`, "_self");

                    navigate(`/`)
                }}><LogoutIcon sx={{ color: "grey" }} />  &nbsp; Logout</MenuItem>
                <hr />
                <div style={{ padding: "0px 20px" }}>
                    <span><b>Server Version</b></span>
                    <br /><span>{serverVersion}</span><p />
                    <span><b>App Version</b></span>
                    <br /><span>{process.env.REACT_APP_VERSION}</span>
                </div>
            </Menu>

        </div>
    )
}