
import * as MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { Button, MenuItem, Select } from '@material-ui/core';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import moment from "moment";
import 'moment/min/locales.min';
import React from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import axios from "../AxiosBase.js";
// import LayerControl from "./LayerControl";
import { drawStyles } from "../drawStyles";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
    "pk.eyJ1IjoidG9taG9sbGluZ3N3b3J0aCIsImEiOiJja21naXE0ZzQwM21oMzFtc2d6NzBuMW52In0.l0xdVRhPuugclpVQUuaBhQ";

export default class SurveyRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            drawnAreasCounter: 0,
            description: "",
            descError: "",
            activePage: "survey-request",
            showDescriptionModal: false,
            setShowDescriptionModal: (to) => { this.setState({ ...this.state, showDescriptionModal: to }) },
            isDrawing: false,
            setDrawing: (to) => { this.setState({ ...this.state, isDrawing: to }) },
            showConfirmBox: false,
            showSuccess: false,
            areaBounds: [],
            layer: [],
            needsRefresh: false,
            allLayers: [],
            loading: false,
            selectedRequestProject: -1
        };
        this.mapContainer = React.createRef();
        this.surveyData = props.surveys
        this.sources = []
        this.polyLayers = []
        this.refresh = props.refresh;
        this.project = props.project
        this.user_id = props.user_id

    }

    setLayers(layers, map, sources, polyLayers) {
        (layers || []).forEach((layer) => {

            let sName = `ext-source-${layer.id}`
            let lName = `ext-layer-${layer.id}`

            let mapLayerIds = map.getStyle().layers.map((l) => l.id)
            let existing = mapLayerIds.filter((id) => id.startsWith(lName))

            // remove layer if not selected
            if (!layer.selected) {
                // remove source from map
                if (map.getSource(sName) && existing.length > 0) {
                    existing.forEach((l) => map.removeLayer(l))
                    map.removeSource(sName)
                }
                return
            }

            // check for existing map
            if (map.getSource(sName) && existing.length > 0) return;

            // add a raster layer to the map
            if (layer.layer_type === "WMS") {
                let url = layer.full_url.replace(layer.title, '')
                // add raster source to map
                map.addSource(sName, {
                    'type': 'raster',
                    // use the tiles option to specify a WMS tile source URL
                    // https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
                    'tiles': [
                        url + '?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=' + layer.server_layer_name
                    ],
                });
                // raster layer for the map
                map.addLayer(
                    {
                        'id': lName,
                        'type': 'raster',
                        'source': sName,
                        'paint': {}
                    }
                );
                return
            }

            // add source for vector layer
            if (layer.layer_type === "VTILES") {
                // add the vector source to the map
                map.addSource(sName, {
                    'type': 'vector',
                    'tiles': [
                        `${layer.full_url}?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=${layer.server_layer_name}&STYLE&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application%2Fvnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`
                    ],
                });
                let styles = JSON.parse(layer.style)
                // add all styles to map
                for (let idx in styles) {
                    let style = styles[idx]
                    map.addLayer(
                        {
                            'id': `${lName}-${idx}`,
                            'source': sName,
                            'source-layer': layer.server_layer_name.split(":")[1],
                            ...style
                        }
                    )

                    sources.push(sName);
                    polyLayers.push(`${lName}-${idx}`)
                    polyLayers.push(lName);
                }
            }
        })
    }

    loadLayers(project, setLayers, map, sources, polyLayers) {
            // fetch layers from backend
            // M stands for Map. To request normal map layers associated with the selected project,
            // Use MI for ImageView layers and use M3D for Map 3D layers
            axios
                .get(process.env.REACT_APP_BACKEND_URL + `/maps/${project}/layers/M/`, {
                    withCredentials: true,
                })
                .then((response) => {
                    console.log(response.data)
                    if (response.status === 200 && Array.isArray(response.data)) {
                        let dta;
                            if(project === 0){
                                dta = response.data[0].filter((l) => l.layer_type !== "FLDR");
                                }
                                else{
                                     dta = response.data.filter((l) => l.layer_type !== "FLDR");
                                }
                        setLayers(dta, map, sources, polyLayers);
                    }
                });
    
    }

    loadRequestedSurveys(surveys, mapbox, sources, polyLayers, project) {
        let survey_ids = []
        surveys && surveys.filter(survey => project !== 0 ? survey.project.id === project : survey).map((survey) => {

            if (survey.status[0] === "R" || survey.status[0] === "A") {
                survey_ids.push(String(survey.ref));
                let mapLayer = mapbox.getLayer(String(survey.ref));
                let polygonColor = survey.is_survey_admin ? "darkorange" : "#222";

                if (typeof mapLayer === 'undefined') {

                    mapbox.addSource(String(survey.ref), {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                    'type': 'Feature',
                                    'properties': {
                                        'id': `<strong>Survey ID: </strong> R-${survey.ref}<br/>`,
                                        'date_requested':
                                            `<strong>Date Requested:</strong> ${moment(survey.date).locale('en-gb').format("L - HH:mm:ss")}<br/>`,
                                        'status': `<strong>Status:</strong> ${survey.status[1]}<br/>`,
                                        'bounds': `<strong>Bounds:</strong> ${String(survey.bounds)}<br/>`,
                                        'description': `<strong>Description:</strong> ${survey.description}<br/>`,
                                        'requested_by': `<strong>Requested By:</strong> ${survey.requested_by.first_name} ${survey.requested_by.last_name}<br/>`,
                                        'project': `<strong>Project:</strong> ${survey.project['name']}<br/>`,
                                        'is_survey_admin': `${survey.is_survey_admin}`
                                    },
                                    'geometry': {
                                        'type': 'Polygon',
                                        // These coordinates outline Maine.
                                        'coordinates': [
                                            survey.bounds[0]
                                        ]
                                    }
                                }
                            ]
                        }
                    });

                    // Add a new layer to visualize the polygon.
                    mapbox.addLayer({
                        'id': String(survey.ref),
                        'type': 'fill',
                        'source': String(survey.ref), // reference the data source
                        'layout': {},
                        'paint': {
                            'fill-color': polygonColor,
                            'fill-opacity': 0.5
                        }
                    });
                    // Add a black outline around the polygon.
                    mapbox.addLayer({
                        'id': String(survey.ref) + "_outline",
                        'type': 'line',
                        'source': String(survey.ref),
                        'layout': {},
                        'paint': {
                            'line-color': '#000',
                            'line-width': 3
                        }
                    });
                    sources.push(String(survey.ref))
                    polyLayers.push(String(survey.ref), String(survey.ref) + "_outline")
                }
            }
        });

        mapbox.on('click', [...survey_ids], (e) => {

        
            const features = mapbox.queryRenderedFeatures(
                [e.point.x, e.point.y],
                { layers: [...survey_ids] }
            );
            let showRequestUser = features[0].properties.is_survey_admin === 'true' ? features[0].properties.requested_by : ""
            new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(`
                ${features[0].properties.id}
                ${features[0].properties.date_requested}
                ${features[0].properties.description}
               ${features[0].properties.project}
               ${showRequestUser}
               ${features[0].properties.status}
                `)
                .addTo(mapbox);
        });
        //${features[0].properties.status}

        mapbox.on('mouseenter', 'places', () => {
            mapbox.getCanvas().style.cursor = 'pointer';
        });
        // Change it back to a pointer when it leaves.
        mapbox.on('mouseleave', 'places', () => {
            mapbox.getCanvas().style.cursor = '';
        });

    }

    componentDidUpdate() {
        if (this.props.project !== this.project) {

            this.project = this.props.project;
            this.polyLayers.map((polygon) => {
                let mapLayer = this.map.getLayer(polygon);
                if (typeof mapLayer !== 'undefined') {
                    this.map.removeLayer(polygon);
                }
            })

            this.polyLayers = []
            this.sources.map((source) => {
                let mapLayer = this.map.getSource(source);
                if (typeof mapLayer !== 'undefined') {
                    this.map.removeSource(source)
                  
                }
            }
            )

            this.sources = []
            this.loadLayers(this.project, this.setLayers, this.map, this.sources, this.polyLayers);
            
        }

        this.loadRequestedSurveys(this.props.surveys, this.map, this.sources, this.polyLayers, this.project);
  
    }
    componentDidMount() {
        
        // initialize the map
        this.map = new mapboxgl.Map({
            container: this.mapContainer.current,
            style: "mapbox://styles/tmas45/ck04e7qr60biy1cukg3s86l2n",
            // style: "mapbox://styles/tmas45/ckqsb3uee5zv218o9n3ow0b3a"
            center: [-4.6, 54.9],
            zoom: 5,
            attributionControl: false,
            doubleClickZoom: false
        });

        // add navigation control to the map
        this.map.addControl(new mapboxgl.NavigationControl());

        let onDrawFinished = (bounds) => { this.setState({ ...this.state, setDrawing: false, showDescriptionModal: true, drawnAreasCounter: 1, areaBounds: bounds }) }
        // ALL YOUR APPLICATION CODE

        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            userProperties: true,
            controls: {
                polygon: false,
                line_string: false,
                trash: false
            },
            styles: drawStyles
        });

        this.map.addControl(this.draw);
        this.map.on('draw.create', function (polygon) {
            onDrawFinished(polygon.features[0].geometry)
        });
        let map = this.map
        let surveys = this.surveyData
        let project = this.project
        let sources = this.sources
        let polyLayers = this.polyLayers
        let loadRequestedSurveys = this.loadRequestedSurveys.bind()
        let loadLayers = this.loadLayers.bind()
        let setLayers = this.setLayers.bind()
        // let setLoading = () => this.setState({ ...this.state, loading: false })

        // Add a data source containing GeoJSON data.
        this.map.on('load', function () {
            loadRequestedSurveys(surveys, map, sources, polyLayers, project);
            loadLayers(project, setLayers, map, sources, polyLayers);
        }
        )
    }

    handleSubmission() {
      
        this.draw.deleteAll();
        let formData = {
            "bounds": this.state.areaBounds,
            "description": this.state.description,
            "project_id": this.props.projects.current.id === 0 ? this.state.selectedRequestProject : this.project
        }
        if ((formData.project_id !== -1)) {
            axios.post(process.env.REACT_APP_BACKEND_URL + "/survey_request/create/",
                formData,
                { withCredentials: true }
            )
                .then((response) => {
                    if (response.status === 201) {
                        this.setState({ ...this.state, showConfirmBox: false, showDescriptionModal: false, isDrawing: false, showSuccess: true, drawnAreasCounter: 0, description: "", areaBounds: [] });
                        this.refresh();
                    }
                });
        }
        else {
            alert("Select a project for this survey")
        }
    }

    render() {

        return (
            <>
             {!this.props.surveys.length > 0 && 
                    <div style={{width:"100vw", height:"calc(100vh - 56px)", backgroundColor:"rgba(255,255,255,0.6)", position:"fixed", top:"0 !important",zIndex:99999, }}>
                        
                        <div style={{position:"absolute", textAlign:"center", top:"50%", left:"50%", transform:"translate(-50%,-50%)"}}>
                        <div className={"loader"}></div>
                        <h5 style={{fontWeight:700, marginTop:"20px"}}>Loading Surveys ...</h5>
                        </div>
                        </div>}
                <div style={{ width: "100vw", height: "calc(100vh - 56px)", position:"relative", backgroundColor: "#FFFFFF", margin: 0, overflow: "hidden", position: "relative" }}>

                   
                    <div style={{ width: "100%", height: "100%", backgroundColor: "#FFFFFF", margin: 0 }} ref={this.mapContainer}
                        className="map-container">

                        <Button variant="contained"
                            type="default"
                            style={{ position: "absolute", top: 20, left: 20, zIndex: 999, fontWeight: 500, color: this.state.drawnAreasCounter > 0 ? "#FFF" : "#FFF", background: this.state.drawnAreasCounter > 0 ? "#e74c3c" : this.state.isDrawing ? "#2E7D32" : "#333", }}
                            onClick={() => {
                                if (this.state.drawnAreasCounter > 0) {
                                    this.draw.deleteAll();
                                    this.setState({ ...this.state, showDescriptionModal: false, drawnAreasCounter: this.state.drawnAreasCounter - 1, isDrawing: false });
                                }
                                else {
                                    if (this.state.isDrawing) {
                                        this.draw.trash()
                                        this.setState({ ...this.state, isDrawing: false })
                                    }
                                    else {
                                        this.draw.changeMode('draw_polygon');
                                        this.setState({ ...this.state, isDrawing: true })
                                    }
                                }
                            }}
                        >
                            {this.state.drawnAreasCounter > 0 ? "Start Over?" : this.state.isDrawing ? "Drawing Active" : "Start Request"}
                        </Button>
                    </div>

                    <div style={{ width: "400px", height: "10%", background: "#FFF", position: "absolute", top: 80, left: 20, borderTop: "1px solid #eee", display: this.state.showDescriptionModal ? "block" : "none" }}>

                        <textarea style={{ width: "100%", height: "250px", padding: 20, boxSizing: "border-box", color: this.state.descError.length > 0 ? "#e74c3c" : "#222", backgroundColor: "#fff", outline: "none", border: "none", resize: "none", float: "left" }}
                            placeholder={this.props.projects.textAreaDesc} value={this.state.descError.length > 0 ? this.state.descError : this.state.description} onChange={(e) => {
                                if (this.state.descError.length > 0) {
                                    this.setState({ ...this.state, descError: "" })
                                } else {
                                    this.setState({ ...this.state, description: e.target.value })
                                }
                            }}>
                        </textarea>
                        {this.props.projects.current.id === 0 &&

                            // <FormControl variant="standard" sx={{ minWidth: 50, maxHeight: "40px", marginLeft: "10px", marginTop: "14px" }}>
                                <Select
                                    id="select-project"
                                    value={this.state.selectedRequestProject}
                                    onChange={(e) => this.setState({ ...this.state, selectedRequestProject: e.target.value })}
                                    autoWidth
                                    style={{ border: "none !important", outline: "none", background: "#FFF", marginTop: "5px", padding: "5px", paddingLeft: "20px", width: "400px" }}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    disableUnderline
                                >
                                    <MenuItem value={-1} key={-1}>Select a project</MenuItem>
                                    {this.props.projects.allProjects.features ? this.props.projects.allProjects.features.map((project, key) => {
                                            return <MenuItem value={project.id} key={key}>{project.properties.name}</MenuItem>
                                    }
                                    ) : <></>}
                                </Select>
                            // </FormControl>
                        }
                        <Button variant="contained" type="default" style={{ width: "100%", margin: "10px 0", backgroundColor: "#222", color: "#FFFFFF" }}

                            onClick={() => {
                                if (this.state.description.length > 0) {
                                    this.setState({ ...this.state, showConfirmBox: true })
                                }
                                else {
                                    this.setState({ ...this.state, descError: "Please tell us why you are requesting this survey" })
                                }
                            }}>Submit Request</Button>

                    </div>

                    {this.state.loading && <h3 style={{ position: "absolute", top: "20px", left: "50%", transform: "translate(-50%,0)", fontWeight: 600, zIndex: 999 }}>Loading Surveys ...</h3>}

                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={this.state.isDrawing}
                        message="Draw the area on the map that you would like surveyed. Double click to end"
                    />

                    <Snackbar open={this.state.showSuccess}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        style={{ marginTop: 50 }}
                        autoHideDuration={6000}
                        onClose={() => this.setState({ ...this.state, showSuccess: false })}>

                        <MuiAlert elevation={6} variant="filled" onClose={() => this.setState({ ...this.state, showSuccess: false })} severity="success" sx={{ width: '100%' }}>
                            Your request has been received
                        </MuiAlert>
                    </Snackbar>

                    <Dialog
                        open={this.state.showConfirmBox}
                        onClose={() => this.setState({ ...this.state, showConfirmBox: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Confirm Request"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Submit this survey request?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ ...this.state, showConfirmBox: false })}>Cancel</Button>
                            <Button onClick={() => this.handleSubmission()} autoFocus>
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div >
            </>
        );
    }

}