import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Login from './Pages/Login';

import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

process.env.VERSION = '0.0.2'

ReactDOM.render(

  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="authenticated" element={<App />} />
      <Route path="login" element={<Login />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
