// import axios from "./AxiosBase.js";
import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './App.css';
import { Header } from './Components/Header.js';
import CreateData from './createData';
import SurveyList from './Pages/SurveyList';
import SurveyRequest from "./Pages/SurveyRequest";

require("dotenv").config();
function App() {

  // application states setup

  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("survey-request");
  const [allSurveyData, setAllSurveyData] = useState([])
  const [projects, setProjects] = useState({
    allProjects: [],
    current: {},
    title: "",
    textAreaDesc: "",
    help_url: "",
    visivi_url: "",
    needsUpdate: false
  });
  const [refresh, setRefresh] = useState(false);
  const [user, setUser] = useState()
  const [viewPermission, setViewPermission] = useState(false);
  const [loading, setLoading] = useState(true)
  const [statusTypes, setStatusTypes] = useState([]);

  // functions to fetch all the required data
  const fetchStatusTypes = () => {
    axios.get(process.env.REACT_APP_BACKEND_URL + "/survey_request/status-types/", {
      withCredentials: true,
    })
      .then((response) => {
        setStatusTypes(response.data)
      });
  }

  const processSurveys = (surveys) => { 
    let temp_array_all = []
    surveys.map((survey) => {
      temp_array_all.push(CreateData(survey.ref, survey.bounds.coordinates[0], survey.description, survey.requested_by, survey.requested_date, survey.status, survey.project, survey.regions, survey.is_survey_admin))
    });

    // check if status is requested, if requested, push into another array as well
    setAllSurveyData(temp_array_all)
    setRefresh(false)

  }

  // fetch all the surveys from the selected project / all projects
  const fetchSurveys = () => {

      axios.get(process.env.REACT_APP_BACKEND_URL + `/survey_request/list/`, {
        withCredentials: true,
      })
        .then((response) => {
          processSurveys(response.data)
        })
}

  const processUserProjects = (response) => {

    let current_project;
    let title = "Book AirOps"
    let description_placeholder = "Please provide us with some more information about your request"
    let book_survey_help_url = ""
    let visivi_url = ""

    if (typeof projects.current.id === 'undefined' || projects.current.id === null) {
      if (response.data.count === 1) {

        current_project = { id: response.data.features[0].id, name: response.data.features[0].properties.name }
        title = response.data.features[0].properties.book_survey_title;
        description_placeholder = response.data.features[0].properties.book_survey_request_description;
        book_survey_help_url = response.data.features[0].properties.book_survey_help_url;
        visivi_url = response.data.features[0].properties.visivi_url;
        localStorage.setItem("current-project", JSON.stringify(current_project));
      }
      else {
        let projectStorage = localStorage.getItem("current-project");

        if (typeof projectStorage !== 'undefined' && projectStorage !== null) {

          current_project = JSON.parse(localStorage.getItem("current-project"))

          let proj = response.data.features.filter(project => project.id === current_project.id)[0];
          if(proj){
          
          title = proj.properties.book_survey_title;
          description_placeholder = proj.properties.book_survey_request_description
          book_survey_help_url = proj.properties.book_survey_help_url
          visivi_url = proj.properties.visivi_url;
          }
          else{        
            current_project = { id: 0, name: "sho-all" }
          }
        }
        else {
          current_project = { id: 0, name: "show-all" }
        }
      }
    }
    else {
      current_project = projects.current
    }

    if(response.data.count >=1){
      setViewPermission(true)
    }
    setLoading(false)
    setProjects({ ...projects, allProjects: response.data, current: current_project, title: title, textAreaDesc: description_placeholder, help_url: book_survey_help_url, visivi_url: visivi_url });
  }

  const fetchUserProjects = () => {
      axios.get(process.env.REACT_APP_BACKEND_URL + "/projects/list/CS", { withCredentials: true})
      .then((response)=>{
      processUserProjects(response)
      }
      )
  }

  const fetchUser = () => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + "/users/user-info/", {
        withCredentials: true,
      })
      .then((response) => {
        setUser(response.data.id);
      });
  }

  // IF THE USER UPDATES THE PROJECT SELECTED

  // APPLICATION LOADED - FETCH ALL DATA REQUIRED FOR THE APPLICATION
  useEffect(() => {
    if (Cookies.get().api_token === undefined) {
      navigate(`/login`);
    }
    else {
      fetchUser();
      fetchUserProjects();
      fetchStatusTypes();
      fetchSurveys();
    }
  }, [])


  // WHEN A SURVEY IS SUBMITTED, REFRESH AND FETCH SURVEYS
  useEffect(() => {
    if (refresh) {
      if (Cookies.get().api_token === undefined || !sessionStorage.getItem("loginState")) {
        navigate(`/login`);
      }
      else if (projects.current.id !== -1 && typeof projects.current.id !== 'undefined' && user !== -1) {
          fetchSurveys();
        }
      
      setRefresh(false)
    }
  }, [refresh])


  if (window.location.pathname === "/authenticated/") {
    let csrf = window.location.href.split("?csrf=")[1].split("&api_token=")[0];
    let api_token = window.location.href.split("&api_token=")[1].split("&go_to=")[0];
    let redirect = window.location.href.split("&go_to=")[1];

    Cookies.set("csrftoken", csrf, { expires: 365 });
    Cookies.set("api_token", api_token, { expires: 365 })
    axios.defaults.headers.common["X-CSRFToken"] = csrf;
    axios.defaults.headers.common["Authorization"] = `Token ${api_token}`;
    sessionStorage.setItem("loginState", true);
    if (redirect !== "") {
      window.location.href = redirect
      return
    }
    navigate(`/`)
  }

  if (window.location.pathname === "/logout/") {
    sessionStorage.setItem("loginState", false);
    Cookies.remove('api_token')
    Cookies.remove('csrftoken')
    localStorage.getItem("current-project")
    navigate(`/`)
  }

  return (
    <div className="App" style={{ margin: 0, overflow: "hidden" }}>
      {viewPermission ?
        <>
          <Header setActivePage={(page) => setActivePage(page)} activePage={activePage} projects={projects} setProject={setProjects} title={projects.title}/>
          <div style={{ marginTop: "56px" }}></div>
          {activePage === "survey-request" ? <SurveyRequest surveys={allSurveyData} projects={projects} project={projects.current.id} refresh={() => setRefresh(true)} user_id={user} /> : <SurveyList surveys={allSurveyData} project={projects.current.id} visivi_url={projects.visivi_url} statusTypes={statusTypes} user_id={user} refresh={() => setRefresh(true)} />}
        </>
        : !loading && <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", textAlign: "center" }}>
          <h2 style={{ color: "#e74c3c", fontWeight: 800 }}>Unauthorised Access</h2>
          You do not have access to this application
          <br />Believe this to be an error? <br/> Contact us at <b>book-air-ops@visivi.com</b>
        </div>
      }
    </div>
  );
}

export default App;