import axios from "axios";
import Cookies from 'js-cookie';

axios.interceptors.response.use(
    function (successRes) {
        if (successRes.data.user_authed === false) {
            sessionStorage.setItem("loginState", false);
            window.location.pathname = '/login';
        }
        return successRes;
    },
    function (error) {
        if (error.message === 'Network Error') {
            console.log("Network Error")
        }
        return Promise.reject(error);
    }
);

axios.defaults.headers.common['X-CSRFToken'] = Cookies.get().csrf
axios.defaults.headers.common['Authorization'] = `Token ${Cookies.get().api_token}`

export default axios;