const CreateData = (ref, bounds, description, requested_by, date, status, project, regions, is_survey_admin) => {
    return {
      ref,
      bounds,
      description,
      requested_by,
      date,
      status,
      project,
      regions,
      is_survey_admin
    };
  }

export default CreateData;
